<template>
  <div class="box-nav">
    <van-nav-bar :title="myconfig.page_title" left-text="" right-text="" left-arrow @click-left="$router.go(-1)" />

    <div v-for="item in myconfig.list" v-bind:key="item.vid">
      <!-- <div>
        {{item.title}}
      </div> -->
      <cp_ship :myvalue="item.vid" :mytitle="item.title" />
      <!-- <cp_ship :myvalue="item.vid" :mytitle="item.title" /> -->
    </div>

  </div>
</template>

<script>
import { educourse_sign_phone, getccconfig_json } from "@/api/index";
import cp_ship from "@/components/cp_ship";
export default {
  name: "setupcenter",
  components: {
    cp_ship,
  },
  data() {
    return {
      peoGeninfos: {},
      testa2: '收集情况说明',
      testa: '5eeda83092a089bff5956ffa6857a812_5',
      myconfig: {},
    }
  },
  mounted() {
    this.get_json()
  },
  methods: {
    getpeoInfos() {
      educourse_sign_phone().then((res) => {
        console.log('数据测试', res)
        this.peoGeninfos = res.data.mydata;
      });
    },
    get_json() {
      getccconfig_json('phone_xuexi').then((res) => {
        console.log('数据测试', res)
        this.myconfig = res.data.mydata;
      });
    }
  },
};
</script>

<style scoped lang="less">
.box-nav {
  width: 100%;
}
/deep/ .van-nav-bar .van-icon {
  color: #333;
}
</style>